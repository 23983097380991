import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import time from '../../assets/time.png';
import Load from '../../assets/Load.png';
import exclama from '../../assets/exclama.png';
import Blue from '../../assets/Blue.png';
import './Chat.css';
import { useAlert } from "react-alert";
import { APIURL, TZ } from "../../Global";
import ShowMoreText from "react-show-more-text";
import { Modal } from 'react-bootstrap';
import axios from "axios";
import Footer from '../Navbar/Footer';
import BotIcon from '../.././assets/chatbot.png'; 

// import { marked } from 'marked';
const Chatbot = () => {
    const alert = useAlert();
    const location = useLocation();
    const navigate = useNavigate();
    const { username, phone, user_id } = location.state || {};
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [uploadMessage, setUploadMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [sessionEnded, setSessionEnded] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const fileInputRef = useRef(null);
    const csrftoken = '';
    const MAX_FILES = 4;
    const MAX_FILE_QUESTIONS = 3;
    const MAX_CHAT_MESSAGES = 5;
    const [fileQuestions, setFileQuestions] = useState({});
    const [chatMessageCount, setChatMessageCount] = useState(0);
    const [learningMessage, setLearningMessage] = useState('');
    const [doneLearningMessage, setDoneLearningMessage] = useState('');
    const [chatLimitMessage, setChatLimitMessage] = useState('');
    const [fileLimitMessage, setFileLimitMessage] = useState('');
    const [isBotDisabled, setIsBotDisabled] = useState(false);
    const [popStateEventFired, setPopStateEventFired] = useState(false);
    const chatWindowRef = useRef(null);
    const [browserBack,setBrowserBack]=useState(false)
    const [loading,setLoading]=useState(false)
    const [browserBackPopup, setBrowserBackPopup] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(true); // State to toggle chat visibility


    const cancelAdmission = (cancelback) => {
     
          
let id=sessionStorage.getItem("userId")
   
    let dataTosend = {}
  
        dataTosend = {
            is_deleted: true

        }
    
    axios
        .put(`${APIURL}/chat/delete-index/?user_id=${id}`, dataTosend, {
            headers: {
                "Content-Type": "application/json",
               
            },
        })
        .then((res) => {
            if (res.statusText==="OK") {
             
              if(!cancelback){
                sessionStorage.removeItem("userId")
                navigate("/");
              }
              setBrowserBackPopup(false)

            } else {
                alert.error(res.data.message);
            }
        })
        .catch((err) => {
            // setErrorMsg("Error in submission");
        });

};



    useEffect(() => {
        const handlePopState = (e) => {
          if (!popStateEventFired) {
            setPopStateEventFired(true);
            setBrowserBack(!browserBack);
    
            if (browserBackPopup) {
               cancelAdmission(false);
            } else {
              setBrowserBackPopup(true);
            }
          }else{
             cancelAdmission(true);
            navigate('/');
            
          }
        };
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [browserBack, browserBackPopup, popStateEventFired]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);
  const BrowserBackOptionPopup = (props) => {
    return (
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}

    >

      <Modal.Body >

      <div className="" style={{width:"100%"}}>
                    <div  style={{width:"100%"}} className="modal-content">
                    <h4>You are leaving from experiencing FelixaBot.
                    <br /> Are you Sure?</h4>
                        <div className="modal-buttons">
                            <button  onClick={() =>{setBrowserBack(!browserBack);setPopStateEventFired(false);setBrowserBackPopup(false);}} >Yes</button>
                            <button onClick={() =>{cancelAdmission(false)}}>No</button>
                        </div>
                    </div>
                </div>
    
        </Modal.Body>
       
       
      </Modal>
    );
  };
    const clearUploadedFiles = useCallback(async () => {
        try {
            const response = await fetch(`${APIURL}/chat/delete-index/?user_id=${user_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setUploadedFiles([]);
            } else {
                console.error('Failed to clear uploaded files.');
            }
        } catch (error) {
            console.error('Error clearing uploaded files:', error);
        }
    }, [user_id]);
    

    useEffect(() => {
        clearUploadedFiles();

        const timer = setTimeout(() => {
            endSession();
        }, 900000);

        return () => clearTimeout(timer);
    }, [clearUploadedFiles]);

    const endSession = async () => {
        try {
            const response = await fetch(`${APIURL}/chat/delete-index/?user_id=${user_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setSessionEnded(true);
                appendIncomingMessage('Session ended. Please contact us for customized trials.', time);
                setTimeout(() => {
                    navigate('/');
                }, 15000);
            } else {
                console.error('Failed to clear uploaded files on session end.');
            }
        } catch (error) {
            console.error('Error clearing uploaded files on session end:', error);
        }
    };


    

   

    const appendOutgoingMessage = (message) => {
        const userMessage = { type: 'user', text: message };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
    };

    const appendIncomingMessage = (message, image = null) => {
        const botMessage = { type: 'bot', text: message, image };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        
    };

    const sendMessageToAPI = async (message) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('message', message);
        formData.append('name', username);
        formData.append('phone', phone);
        formData.append('user_id', user_id);

        const currentFile = uploadedFiles[uploadedFiles.length - 1];
        const fileQuestionCount = fileQuestions[currentFile] || 0;

        if (uploadedFiles.length > 0 && fileQuestionCount < MAX_FILE_QUESTIONS) {
            setFileQuestions(prevState => ({
                ...prevState,
                [currentFile]: fileQuestionCount + 1
            }));
        }
        //  else if (uploadedFiles.length > 0 && fileQuestionCount >= MAX_FILE_QUESTIONS) {
        //     const limitMessage = 'Question limit reached for this file.';
        //     appendIncomingMessage(limitMessage);
        //     setFileLimitMessage(limitMessage);
        //     setTimeout(() => {
        //         navigate('/contact');
        //     }, 5000);
        //     setIsBotDisabled(true);
        //     return;
        // }

        else if (uploadedFiles.length > 0 && fileQuestionCount >= MAX_FILE_QUESTIONS) {
            setLoading(false)
            const limitMessage = 'Question limit reached for this file.';
            appendIncomingMessage(limitMessage);
            setFileLimitMessage(limitMessage);
            showPopup(limitMessage);
            setIsBotDisabled(true);
            navigate('/contact'); 
            return;
        }
        //   else if (chatMessageCount >= MAX_CHAT_MESSAGES) {
        //      const limitMessage = 'Chat limit reached. You can only send five messages.';
        //      setMessages(prevMessages => [...prevMessages, { type: 'bot', text: limitMessage, image: exclama }]);
        //      setChatLimitMessage(limitMessage);
        //      setTimeout(() => {
        //         navigate('/contact');
        //      }, 5000);
        // setIsBotDisabled(true);
        //  return;
        //  }
        else if (chatMessageCount >= MAX_CHAT_MESSAGES) {
            setLoading(false)
            const limitMessage = 'Chat limit reached. You can only send five messages.';
            setMessages(prevMessages => [...prevMessages, { type: 'bot', text: limitMessage, image: exclama }]);
            setChatLimitMessage(limitMessage);
            showPopup(limitMessage);
            setIsBotDisabled(true);
            navigate('/contact'); 
            return;
        }
        else {
            setChatMessageCount(prevCount => prevCount + 1);
        }

        try {
            const response = await fetch(`${APIURL}/chat/chat/`, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-CSRFToken': csrftoken,
                },
            });

            const data = await response.json();
            const responseMessage = data.response.replace(/Sure, here's an HTML-formatted text explaining .*?\s*```html\s*|\s*```/gs, '')
            .trim();
             const finalHtml = responseMessage.replace(/^\s+|\s+$|\n\s*/g, '');
            const formattedContent = data.response.replace(/\n\n/g, '<p></p>').replace(/\n/g, '<br>');
            appendIncomingMessage(finalHtml);
            setLoading(false)
        } catch (error) {
            console.error('Error:', error);
            appendIncomingMessage('Please try again');
            setLoading(false)
        }
    };
   
    const uploadFileToAPI = async (file) => {
        setIsBotDisabled(true)
        let filePath=""
        if (uploadedFiles.length >= MAX_FILES) {
            alert.info('You can only upload up to four files.');
            return;
        }
        
        setFileQuestions(prevState => ({
            ...prevState,
            [file.name]: 0
        }));
    
        const formData = new FormData();
        formData.append('files', file);
        formData.append('user_id', user_id);
    
        try {
            setUploading(true);
            // appendIncomingMessage('Uploading');
            const response = await fetch(`${APIURL}/chat/file-upload2/`, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-CSRFToken': csrftoken,
                },
            });
    
            if (!response.ok) {
                setIsBotDisabled(false)
                throw new Error('Network response was not ok');
                
            }
    
            const data = await response.json();
            if (data.status === 'success') {
                setUploadedFiles((prevFiles) => [...prevFiles, file.name]);
               
                // setUploadMessage('Successfully uploaded the file');
                appendIncomingMessage(`Successfully uploaded - ${file.name}`);
                filePath=data.file_url
                    setUploadMessage('');
                
                
                //  if (data.summary) {
                //     console.log('Summary:', data.summary); 
                //     appendIncomingMessage(`Summary: ${data.summary}`);
                // } else {
                //     console.log('No summary found in the response');
                //     appendIncomingMessage('No summary available for this file.');
                // }

    
                // Show learning message 15 seconds after success message
              
                    const learningMessage = 'We are Learning the file. Please wait...';
                    setLearningMessage(learningMessage);
                    // appendIncomingMessage(learningMessage);
    
                    // Show done learning message 15 seconds after learning message
                    
                  
                    const formData = new FormData();
                     formData.append('file_path', filePath);
                    formData.append('name', username);
                    formData.append('phone', phone);
                    formData.append('user_id', user_id);
                   
                    
                    axios
                        .post(`${APIURL}/chat/document-summary/ `,formData, {
                            headers: {
                                "Content-Type": "application/json",
                               
                            },
                        })
                        .then((res) => {
                            if (res.statusText==="OK") {
                             
                                appendIncomingMessage(res.data.summary);

                                setIsBotDisabled(false)
                              setLearningMessage('');
                        setMessages(prevMessages =>
                            prevMessages.filter(msg => msg.text !== learningMessage)
                        );
    
                        // const doneMessage = 'Done, We completed the Lessons.';
                        // setDoneLearningMessage(doneMessage);
                        // appendIncomingMessage(doneMessage);
    
                        // Remove done learning message after 15 seconds
                        // setTimeout(() => {
                        //     setDoneLearningMessage('');
                        //     setMessages(prevMessages =>
                        //         prevMessages.filter(msg => msg.text !== doneMessage)
                        //     );
                        // }, 10000);
                
                            } else {
                                alert.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            // setErrorMsg("Error in submission");
                        });
               // 15 seconds after success message
            } else {
                const errorMessage = `Error: ${data.message} ${file.name}`;
                alert.error(errorMessage)
                // appendIncomingMessage(errorMessage, exclama);
                console.error('Error uploading file:', data);
                setIsBotDisabled(false)
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            appendIncomingMessage('Please try again');
            setIsBotDisabled(false)
        } finally {
            setUploading(false);
            setMessages(prevMessages =>
                prevMessages.filter(msg => msg.text !== 'Uploading')
            );
            setIsBotDisabled(false)
        }
    };


    const sendMessage = () => {
        if (input) {
            appendOutgoingMessage(input);
            sendMessageToAPI(input);
            setInput('');
            setShowButtons(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const handleQuestionButtonClick = () => {
        appendOutgoingMessage('I have a question');
        sendMessageToAPI('I have a question');
        setShowButtons(false);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            uploadFileToAPI(file);
            setShowButtons(false);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const showPopup = (message) => {
        const popup = document.getElementById('popupMessage');
        const popupText = document.getElementById('popupText');
        
        if (window.innerWidth <= 850) {
          popupText.textContent = message;
          popup.style.display = 'block';
          
          setTimeout(() => {
            popup.style.display = 'none';
          }, 15000); // Hide after 15 seconds
        }
      };
      useEffect(() => {
        const handleResize = () => {
          const popup = document.getElementById('popupMessage');
          if (window.innerWidth > 850) {
            // popup.style.display = 'none';
          }
        };
      
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);


      const scrollToBottom = () => {
        if (chatWindowRef.current) {
          chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
      };

      useEffect(() => {
        scrollToBottom();
      }, [messages]);
      
      const toggleChat = () => {
        setIsChatOpen(!isChatOpen); // Toggle chat visibility
    };

    return (
        <>
            {/* Chat Icon */}
            <div
                className="chat-toggle-icon"
                onClick={toggleChat}
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '0px',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    backgroundColor: '#007bff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    zIndex: 9999,
                }}
            >
                <img src={BotIcon} alt="Chat Icon" style={{ width: '40px', height: '40px' }} />
            </div>

            {/* Conditionally Render Chat */}
            {isChatOpen && (
                <div style={{ position: 'fixed', right: '20px', bottom: '80px' }} className={`next-chatbot ${isBotDisabled ? 'disabled' : ''}`}>
                    <div className="next-header">
                        <div className="next-bot-icon">🤖</div>
                        <div className="next-bot-status">
                            <p className="next-bot-name">FelixaBot</p>
                            <p className="next-status">Online</p>
                        </div>
                    </div> 

                    <div className="next-chat-window" ref={chatWindowRef}>
                        <div className="message-avatar">
                            <span className="avatar-icon"><button className="aibot-circle-button">🤖</button></span>
                            &nbsp; &nbsp;
                            <span><div className='bot-navbar-name'>FelixaBot</div></span>
                        </div>

                        <div className="message-container bot-message">
                            <div className="message-content">
                                Hi there! 😊 I'm here to assist you. How can I help you today?
                            </div>
                        </div>

                        {messages.map((msg, index) => (
                            <div key={index} className={`message-container ${msg.type}-message`}>
                                <div className="message-content">
                                    <ShowMoreText
                                        lines={3}
                                        more={<span>More</span>}
                                        less={<span>Less</span>}
                                        className="content-css display-linebreak"
                                        expanded={false}
                                        truncatedEndingComponent={"... "}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                                    </ShowMoreText>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="next-input-area">
                        <div className="next-input-wrapper">
                            <input
                                type="text"
                                placeholder="Type your message..."
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                                disabled={isBotDisabled}
                            />
                            <button 
                                className='file-next-input-area' 
                                id="upload-symbols" 
                                onClick={triggerFileInput}
                                disabled={isBotDisabled}
                            >
                                &#128194;
                            </button>
                            <button 
                                className='send-next-input-area' 
                                id="send-symbols" 
                                onClick={sendMessage}
                                disabled={isBotDisabled}
                            >
                                &#10148;
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {browserBackPopup && (
                <BrowserBackOptionPopup
                    show={browserBackPopup}
                    onHide={() => setBrowserBackPopup(false)}
                />
            )}
        </>
    );
};

export default Chatbot;