import React from 'react';
import '../App.css';

const WidgetDemo = () => {
    // Determine the iframe source based on the environment
    const iframeSrc = window.location.hostname === 'localhost'
        ? "http://localhost:3000/chatwidget"
        : "https://devaibot.cianlogic.com/chatwidget";

    return (
        <div className="iframe-demo-container" style={{ textAlign: 'center', padding: '50px' }}>
            <h1>Chatbot Demo</h1>
            <p>This is a simple demo showcasing the chatbot iframe embedded into a website.</p>
            
            {/* Conditionally set the iframe source */}
            <iframe 
                src={iframeSrc} 
                width="100%" 
                height="700" 
                style={{ border: 'none' , position:'absolute', right: '10px', bottom: '10px' }} 
                allow="camera; microphone" 
                title="Chatbot Demo"
            />
        </div>
    );
}

export default WidgetDemo;