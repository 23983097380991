import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Bot from '../../assets/Bot.png';
import './Contact.css';

const Contact = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    function usePreventBackButton() {
        useEffect(() => {
            const preventBackNavigation = (e) => {
                e.preventDefault();
                setShowModal(true);
                window.history.pushState(null, null, window.location.pathname);
            };

            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', preventBackNavigation);

            return () => {
                window.removeEventListener('popstate', preventBackNavigation);
            };
        }, []);
    }

    usePreventBackButton();

    const handleConfirm = () => {
        setShowModal(false);
        navigate('/');
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div className="contact-container">
            <div className="contact-content-container">
                <div className="contact-image-container">
                    <img src={Bot} alt="Contact" className="contact-image" />
                </div>
                <div className="contact-header">
                    <h2>This is a demo version; hence, there are limitations.<br /> 
                    <br />
                    You can train Bot using FOUR pdf files. Bot now allows you to ask only THREE questions.
                    You want an unlimited version? Send us an email with your requirements.</h2>
                    <br />
                    <h2>Email us: contact@intpurple.com</h2>
                </div>
            </div>
            {showModal && (
                <div className="modal1">
                    <div className="modal-content">
                    <h4>You are leaving from experiencing FelixaBot.
                    <br /> Are you Sure?</h4>
                        <div className="modal-buttons">
                            <button onClick={handleConfirm}>Yes</button>
                            <button onClick={handleCancel}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Contact;
