import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AlertTemplate from "react-alert-template-basic";
import { transitions, positions,types, Provider as AlertProvider } from 'react-alert';
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 4000,
  offset: '20px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  types:types.SUCCESS,
   
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AlertProvider containerStyle={{fontSize:"12px",zIndex:"10000000000"}} template={AlertTemplate} {...options}>
     <App />
     </AlertProvider>

  </React.StrictMode>
);
