

let backendHost;
let socketHost;

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const hostname = window && window.location && window.location.hostname;
 if(hostname === 'localhost') {
  backendHost = 'https://devaibotapi.cianlogic.com';
  // socketHost = 'wss://devaibotapi.cianlogic.com';
}
else if(hostname === 'aibot.felixacare.com') {
  backendHost = 'https://aibotapi.felixacare.com';
  // socketHost = 'wss://svpapidev.cianlogic.com';

}
else if(hostname === 'devaibot.cianlogic.com'){
  backendHost = 'https://devaibotapi.cianlogic.com';
  socketHost = 'wss://devaibotapi.cianlogic.com';
}


export const APIURL = `${backendHost}`;
export const SOCKETURL = `${socketHost}`;
export const TZ = `timeZone=${currentTimezone}`;




