import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo1 from "../../assets/felixa_logo.png";

const Footer = () => {
    return(
        <div className='footer'>
            <div className='' style={{display:"flex",lineHeight:"25px"}}>
            
                    <img width={60} height={60}className="logo-img" src={logo1} alt="Logo" />&nbsp;&nbsp;
                   
                <div className='' style={{width:"75%",paddingTop:"0.5%",margin:"auto"}}>
                  
               
                <span>IntPurple technologies</span><br/>
 <span>India | Malta</span><br/>
 <span><a
 style={{textDecoration:"none", color:"rgb(117 194 246)"}}
          aria-label="mail id"
          href="mailto:Contact@IntPurple.com"
          target="_blank"
          rel="noopener noreferrer"
        >
        <i class="fa fa-envelope" aria-hidden="true"></i><span >contact@IntPurple.com</span>
        </a>
  </span>

                </div>
                <div className='' style={{float:"right",paddingTop:"0.5%",margin:"auto"}}>
                <a
                style={{textDecoration:"none", color:"rgb(117 194 246)"}}
          href={"https://IntPurple.com"}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="website"
        >
        www.IntPurple.com
        </a>
                  <br/>
                   <a
                style={{textDecoration:"none", color:"rgb(117 194 246)"}}
          href={"https://felixacare.com"}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="website"
        >
        www.felixacare.com
        </a>
                  
                </div>
            </div>
        </div>
    )
}
export default Footer;