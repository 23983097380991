import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar/Navbar';
import Next from './components/Next/Next';
import Chat from './components/ChatWidget/Chat';
import Contact from './components/Contact/Contact';
import HomePage from "./components/HomePage";
import WidgetDemo from "./components/WidgetDemo";
import ChatBot from "./components/ChatWidget/ChatWidget";
import Footer from "./components/Navbar/Footer";
import { useLocation } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const [isFooterFixed, setIsFooterFixed] = useState(false);
  const location = useLocation(); // This is now inside the BrowserRouter

  useEffect(() => {
    const handleResize = () => {
      // Get the document's total height
      const contentHeight = document.documentElement.scrollHeight;
      // Get the window's height
      const windowHeight = window.innerHeight;

      // If the content height is less than or equal to the window height, fix the footer
      if (contentHeight <= windowHeight) {
        setIsFooterFixed(true);
      } else {
        setIsFooterFixed(false);
      }
    };


    // Attach event listener to window resize
    window.addEventListener("resize", handleResize);
    // Initial check when component mounts
    handleResize();

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  // Change body background when on "/ChatWidget" route
  useEffect(() => {
    const normalizedPath = location.pathname.toLowerCase();

    if (normalizedPath === "/chatwidget" || normalizedPath === "/chat" || normalizedPath === "/widgetdemo" ) {
      document.body.style.background = "none"; // Remove background
    } else {
      document.body.style.background = ""; // Reset to original background
    }

    // Clean up on unmount
    return () => {
      document.body.style.background = "";
    };
  }, [location]);

  const normalizedPath = location.pathname.toLowerCase();

  const showFooter = normalizedPath !== "/chatwidget" && normalizedPath !== "/chat" && normalizedPath !== "/widgetdemo";

  return (
    <div className="app-grid">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/widgetdemo" element={<WidgetDemo />} />
        <Route path="/chatwidget" element={<ChatBot />} />
        <Route path="/Navbar" element={<Navbar />} />
        <Route path="/Next" element={<Next />} />
        <Route path="/Chat" element={<Chat />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>

      {/* Conditionally render the footer */}
      {showFooter && <Footer className={isFooterFixed ? "footer fixed" : "footer"} />}
    </div>
  );
};

export default App;