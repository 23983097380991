import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css'; // Keep the existing CSS
import { useAlert } from "react-alert";
import BotIcon from '../.././assets/chatbot.png'; 
import { APIURL } from "../../Global"; // Assuming TZ is not used

const ChatWidget = () => {
    const alert = useAlert();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [showOtpPopup, setShowOtpPopup] = useState(false);
    const [otp, setOtp] = useState('');
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isOtpFieldVisible, setIsOtpFieldVisible] = useState(false); // Control OTP visibility
    const [isOpen, setIsOpen] = useState(false); // For toggling the widget
    const navigate = useNavigate();

    const validateEmail = (email) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    const validatePhone = (phone) => /^\d{10}$/.test(phone);

    const callSendOtpApi = async () => {
        try {
            const response = await fetch(`${APIURL}/chat/send-otp/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            if (data.status === 'success') {
              setIsOtpFieldVisible(true); // Show OTP field on success
            }
            else alert.error(data.message);
        } catch (error) {
            console.error('Error sending OTP:', error);
        }
    };

    const handleSendOtp = async () => {
        if (!validateEmail(email)) {
            setErrors({ ...errors, email: 'Invalid email address' });
            return;
        }
        await callSendOtpApi();
    };

    const callVerifyOtpApi = async () => {
        try {
            const response = await fetch(`${APIURL}/chat/verify-otp/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, otp }),
            });
            return await response.json();
        } catch (error) {
            console.error('Error verifying OTP:', error);
        }
    };

    const handleVerifyOtp = async () => {
        const result = await callVerifyOtpApi();
        if (result?.status === 'success') {
            setIsOtpFieldVisible(false); // Hide OTP field after verification
            setIsOtpVerified(true);
            setShowOtpPopup(false);
            alert.success('OTP verified successfully');
        } else {
            alert.error('Invalid OTP. Please try again.');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isOtpVerified) {
            alert.error('Please verify your email with OTP before registering.');
            return;
        }

        if (!username || !validateEmail(email) || !validatePhone(phone)) {
            setErrors({ username: !username ? 'Name is required' : '', email: !validateEmail(email) ? 'Invalid email' : '', phone: !validatePhone(phone) ? 'Phone number must be 10 digits' : '' });
            return;
        }

        try {
            const response = await fetch(`${APIURL}/chat/signup/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: username, email, mobile_number: phone }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                sessionStorage.setItem('userId', data.user_id);
                navigate('/chat', { state: { username, phone, user_id: data.user_id } });
            } else {
                alert.error(data.message);
            }
        } catch (error) {
            alert.error('Error occurred while processing your request.');
        }
    };

    // Toggle widget visibility (open/close)
    const toggleWidget = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* Chat Icon */}
            <div
                className="chat-widget-icon"
                onClick={toggleWidget}
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    backgroundColor: '#007bff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    zIndex: 9999,
                }}
            >
                <img src={BotIcon} alt="Chat Icon" style={{ width: '40px', height: '40px' }} />
            </div>

            {/* Chat Form */}
            {isOpen && (
                <div
                    className="chat-widget-container"
                    style={{
                        position: 'fixed',
                        bottom: '80px',
                        right: '0px',
                        width: '100%',
                        height: '600px',
                        borderRadius: '10px',
                        zIndex: 999,
                        padding: '0px',
                    }}
                >
                    {/* HomePage UI */}
                    <div className="login-container">
                        <div className="content-container">
                                                        
                            <div className="signup-container" style={{ width: '95%' , background: 'linear-gradient(135deg, #01122d, #808080)' }}>                                <header>
                                    <h2>Register</h2>
                                </header>
                                <div className="signup-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-container">
                                            <label htmlFor="username">Name:</label>
                                            <div className="email-input-container">
                                                <input
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    required
                                                />
                                                {errors.username && <span className="error">{errors.username}</span>}
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <label htmlFor="emailPhone">Email:</label>
                                            <div className="email-input-container">
                                                <input
                                                    type="email"
                                                    id="emailPhone"
                                                    name="emailPhone"
                                                    value={email}
                                                    onChange={(e) => { setEmail(e.target.value); setIsOtpVerified(false); setErrors({ ...errors, email: '' }); }}
                                                    required
                                                />
                                                {email !== "" && (isOtpVerified
                                                    ? <svg className="send-otp-button" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="green" d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682l-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72"/></svg>
                                                    : <button type="button" className="send-otp-button" onClick={handleSendOtp}>Send OTP</button>
                                                )}
                                            </div>
                                            {errors.email && <span className="error">{errors.email}</span>}
                                        </div>



                                                                                
                                        {isOtpFieldVisible && (
                                            <div className="input-container">
                                                <label htmlFor="otp">Enter OTP:</label>
                                                <div className="email-input-container">
                                                    <input
                                                        type="text"
                                                        id="otp"
                                                        name="otp"
                                                        value={otp}
                                                        onChange={(e) => setOtp(e.target.value)}
                                                        placeholder="Enter OTP"
                                                    />
                                                    <button className="send-otp-button"  style={{ marginTop: "1%" }} onClick={handleVerifyOtp}>Verify OTP</button>
                                                </div>
                                            </div>
                                        )}



                                        <div className="input-container">
                                            <label htmlFor="Phone">Phone number:</label>
                                            <div className="email-input-container">
                                                <input
                                                    type="text"
                                                    id="Phone"
                                                    name="Phone"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    required
                                                />
                                                {errors.phone && <span className="error">{errors.phone}</span>}
                                            </div>
                                        </div>
                                        <input type="submit" value="Start" />
                                    </form>
                                </div>
                            </div> {/* Closing for signup-container */}
                        </div> {/* Closing for content-container */}
                    </div> {/* Closing for login-container */}
                </div>
            )}

            {showOtpPopup && (
                <div className="otp-popup">
                    <div className="otp-popup-content">
                        <h3>Enter OTP</h3>
                        <input
                            type="text"
                            value={otp}
                            onChange={(e) =>setOtp(e.target.value)}
                placeholder="Enter OTP"
                />
                You will receive an OTP at the email address <span style={{ color: "blue" }}>{email}</span>

                <button style={{ marginTop: "1%" }} onClick={handleVerifyOtp}>Verify</button>
                <button onClick={() => setShowOtpPopup(false)}>Close</button>

                </div>
                </div>

                )}
                </div>
                );
                };

export default ChatWidget;