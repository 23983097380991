import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ uploadedFiles, triggerFileInput }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { username, phone, user_id } = location.state || {};



    const truncateFileName = (fileName) => {
        return fileName.length > 25 ? fileName.slice(0, 22) + '...' : fileName;
    };

    return (
        <nav className="navbar">
            <div className="navbar-nav">
                <div className="navbar-header">
                    <button className="circle-button">🐰</button>
                    <h4 className='navbar-name'>{username}</h4>
                </div>
                <div style={{textAlign:"center",justifyContent:"center"}}>
                    <h4 className='pdf'>Train the model using  your data files. </h4>
                    <button className="upload-btn" onClick={triggerFileInput}>
                        Train Me
                    </button>
                </div>
                <div className="uploaded-files-list " style={{textAlign:"center",justifyContent:"center"}}>
                    <h5  className='pdf'>Upload the files in pdf format</h5>
                    {uploadedFiles.map((file, index) => (
                        <div key={index} className="uploaded-file">
                            <h4>{truncateFileName(file)}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;