import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { useAlert } from "react-alert";
import Bot from './Bot.png';
import { APIURL, TZ } from "../Global";
const HomePage= () => {
    const alert = useAlert();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [showOtpPopup, setShowOtpPopup] = useState(false);
    const [otp, setOtp] = useState('');
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(email);
    };

    const validatePhone = (phone) => {
        const re = /^\d{10}$/;
        return re.test(phone);
    };

    const callSendOtpApi = async () => {
        try {
            const response = await fetch(`${APIURL}/chat/send-otp/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (!response.ok) {
                alert.error(response.data.message)
                throw new Error('Failed to send OTP');
            }
            const data = await response.json();
            if (data.status === 'success') {
                setShowOtpPopup(true);
            } else {
                alert.error(data.message);
            }
           
            return await response.json();
            
        } catch (error) {
            console.error('Error sending OTP:', error);
            throw error;
        }
    };

    const handleSendOtp = async () => {
        if (!validateEmail(email)) {
            setErrors({ ...errors, email: 'Invalid email address' });
            return;
        }

        try {
            await callSendOtpApi();
           
        } catch (error) {
            // alert.error('Failed to send OTP. Please try again.');
        }
    };

    const callVerifyOtpApi = async () => {
        try {
            const response = await fetch(`${APIURL}/chat/verify-otp/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, otp }),
            });
            if (!response.ok) {
                throw new Error('Failed to verify OTP');
            }
            return await response.json();
        } catch (error) {
            console.error('Error verifying OTP:', error);
            throw error;
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const result = await callVerifyOtpApi();
            if (result.status === 'success') {
                setIsOtpVerified(true);
                setShowOtpPopup(false);
                setOtp('');
                alert.success('OTP verified successfully');
            } else {
                alert.error('Invalid OTP. Please try again.');
            }
        } catch (error) {
            alert.error('Failed to verify OTP. Please try again.');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!isOtpVerified) {
            alert.error('Please verify your email with OTP before registering.');
            return;
        }

        const newErrors = {};
        
        if (username.trim() === '') {
            newErrors.username = 'Name is required';
        }
        
        if (!validateEmail(email)) {
            newErrors.email = 'Invalid email address';
        }
        
        if (!validatePhone(phone)) {
            newErrors.phone = 'Phone number must be 10 digits';
        }
        
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const response = await fetch(`${APIURL}/chat/signup/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: username,
                    email: email,
                    mobile_number: phone,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.status === 'success') {
                sessionStorage.setItem('userId', data.user_id);
                navigate('/Next', {
                    state: { username, phone, user_id: data.user_id },
                });
            } else {
                alert.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert.error('Error occurred while processing your request. Please try again later.');
        }
    };

    //     const ConButtons = () => {
//         const navigate = useNavigate();
    
//    const handleContactButtonClick = () => {
//         navigate('/Contact'); 
//       };
      
//       return (
//         <div className='contactbutton'>
//           <button type='button' className='con-button' onClick={handleContactButtonClick}>Contact Us</button>
//         </div>   
//       );
//     };
  
    
    return (
        <div className="login-container"  >

            <div className="content-container">
                <div className="login-header">
                    <h1>FelixaBot for your Office</h1>
                    <h2>This is not a chatBot, but an AI Bot.
                        This Bot uses only publicly available data to respond, until you train the model.
                        Trainable with your data. Customizable for your needs</h2>
                    <br/>
                    <h2>Email us: contact@intpurple.com</h2>

                  
                </div>
                <div className="login-image-container">
                    <img src={Bot} alt="Login" className="login-image" />
                </div>
              
                <div className="signup-container">
                    <header>
                        <h2>Register</h2>
                    </header>
                    <div className='signup-form'>
                        <form onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label htmlFor="username">Name:</label>
                                <div className="email-input-container">
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                                 {errors.username && <span className="error">{errors.username}</span>}
                            </div>
                            </div>
                            <div className="input-container">
                            <label htmlFor="emailPhone">Email:</label>
                            <div className="email-input-container">
                                
                                <input
                                    type="email"
                                    id="emailPhone"
                                    name="emailPhone"
                                    value={email}
                                    onChange={(e) =>{ setEmail(e.target.value);setIsOtpVerified(false);setErrors({ ...errors, email: '' });}}
                                    required
                                />
                               {email!==""&&(isOtpVerified?<svg className="send-otp-button"xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="green" d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682l-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72"/></svg>:
                                <button type="button" className="send-otp-button" onClick={handleSendOtp}>
                                    Verify
                                </button>)}
                            </div>
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                            
                            <div className="input-container">
                                <label htmlFor="Phone">Phone number:</label>
                                <div className="email-input-container">
                                <input
                                    type="text"
                                    id="Phone"
                                    name="Phone"
                                   
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                />
                                {errors.phone && <span className="error">{errors.phone}</span>}
                            </div>
                            </div>
                            
                            <input  type="submit" value="Start" />
                        </form>
                    </div>
                </div>
               
                {showOtpPopup && (
                <div className="otp-popup">
                    <div className="otp-popup-content">
                        <h3>Enter OTP</h3>
                        <input
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter OTP"
                        />
                         <small style={{}} className="">You will receive an OTP at the email address <span style={{color:"blue"}}>{email}</span> </small>
                     <div>
                        <button style={{marginTop:"1%"}} onClick={handleVerifyOtp}>Verify</button>
                        <button onClick={() => setShowOtpPopup(false)}>Close</button>
                    </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    );
}

export default HomePage;
